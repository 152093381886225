import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { useState } from '@/lib/composables';
import { DashboardService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { PacientesPorSexoChart } from './pacientes/PacientesPorSexoChart';
import { PacientesPorTipoChart } from './pacientes/PacientesPorTipoChart';

export const PacientesCard = createComponent({
  name: 'PacientesCard',
  props: {
    value: { type: String },
  },
  setup(props, ctx) {
    const $loading = useState(s => s.dashboard.pacientes.loading);

    const $noData = useState(s => {
      const { porSexo, porTipo } = s.dashboard.pacientes;

      return porSexo.length === 0 || porTipo.length === 0;
    });

    return () => (
      <DashboardCard
        id="PacientesCard"
        title="Pacientes"
        loading={$loading.value}
        updateAction={DashboardService.atendimentosCard}
        noData={$noData.value}
      >
        <div class="flex w-full">
          <PacientesPorSexoChart />

          <PacientesPorTipoChart />
        </div>
      </DashboardCard>
    );
  },
});
