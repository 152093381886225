import { DashboardSubtitle } from '@/components/dashboard/DashboardSubtitle';
import { useState } from '@/lib/composables';
import { formatInteger } from '@/lib/form';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';

export const AtendimentosDuracao = createComponent({
  name: 'AtendimentosDuracao',
  setup(props, ctx) {
    const $duracao = useState(s => s.dashboard.atendimentos.duracao);

    return () => {
      const minutosLabel = $duracao.value === 1 ? 'minuto' : 'minutos';

      return (
        <div class="flex flex-col w-full">
          <DashboardSubtitle text="Duração total" />

          <div class="flex items-center">
            <v-icon size={40} color={MyTheme.coolGray600}>
              {MyIcons.timerOutline}
            </v-icon>

            <span class="ml-2 text-display text-coolGray-600">
              {formatDuracao($duracao.value)} {minutosLabel}
            </span>
          </div>
        </div>
      );
    };
  },
});

function formatDuracao(total: number) {
  return formatInteger(total / 60);
}
