import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { useState } from '@/lib/composables';
import { DashboardService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { AtendimentosDuracao } from './atendimentosCard/AtendimentosDuracao';
import { ProcedimentosPorConvenioChart } from './atendimentosCard/ProcedimentosPorConvenioChart';
import { ProcedimentosRealizadosChart } from './atendimentosCard/ProcedimentosRealizadosChart';

export const AtendimentosCard = createComponent({
  name: 'AtendimentosCard',
  setup(props, ctx) {
    const $loading = useState(s => s.dashboard.atendimentos.loading);

    const $noData = useState(
      s =>
        s.dashboard.atendimentos.porConvenios.length === 0 &&
        s.dashboard.atendimentos.procedimentosRealizados.length === 0,
    );

    return () => (
      <DashboardCard
        title="Atendimentos"
        loading={$loading.value}
        updateAction={DashboardService.atendimentosCard}
        noData={$noData.value}
      >
        <AtendimentosDuracao />

        <div class="flex w-full mt-6">
          <ProcedimentosPorConvenioChart />

          <ProcedimentosRealizadosChart />
        </div>
      </DashboardCard>
    );
  },
});
