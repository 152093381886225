import { LineChart } from '@/components/charts/LineChart';
import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { useState } from '@/lib/composables';
import { ChartHelpers } from '@/lib/helpers/chart.helpers';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { DashboardService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IChartYScale, IDashboardResult, IChartData } from '@/typings';
import { computed, ComputedRef } from '@vue/composition-api';

export const DistribuicaoEtariaCard = createComponent({
  name: 'DistribuicaoEtariaCard',
  setup(props, ctx) {
    const $state = useState(s => s.dashboard.distribuicaoEtaria);

    const $chartData = useChartData($state);

    const $yScale = useYScale($state);

    return () => {
      const { data, loading } = $state.value;

      return (
        <DashboardCard
          title="Distribuição etária"
          updateAction={DashboardService.distribuicaoEtaria}
          noData={data.length === 0}
          loading={loading}
        >
          <LineChart
            value={[
              {
                label: 'Atendimentos',
                data: $chartData.value,
                color: MyTheme.blue500,
              },
            ]}
            yScale={$yScale.value}
            xScale={{ title: 'Idades' }}
          />
        </DashboardCard>
      );
    };
  },
});

function useChartData($state: ComputedRef<IDashboardResult>) {
  return computed<IChartData[]>(() =>
    ChartHelpers.line.mapIntegers($state.value.data),
  );
}

function useYScale($state: ComputedRef<IDashboardResult>) {
  return computed<IChartYScale>(() => {
    const numbers = $state.value.data.map(v => v.value);

    return {
      title: 'Atendimentos',
      max: numbers.length ? Math.max(...numbers) + 1 : undefined,
      integer: true,
      beginAtZero: true,
    };
  });
}
