import { uuid } from '@/lib/helpers/uuid';
import { createComponent, watchRun } from '@/lib/vue';
import { onMounted } from '@vue/composition-api';
import { CountUp as CountUpJs } from 'countup.js';

interface IProps {
  value: number;
  money?: boolean;
}

interface IEvents {}

export const NumberCountUp = createComponent<IProps, IEvents>({
  name: 'NumberCountUp',
  props: {
    value: { type: Number, default: 0 },
    money: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    let count: CountUpJs | null = null;

    const id = uuid();

    onMounted(() => {
      count = new CountUpJs(id, props.value, {
        separator: '.',
        decimal: ',',
        decimalPlaces: props.money ? 2 : 0,
        prefix: props.money ? 'R$ ' : '',
      });

      if (count.error) return console.error(count.error);

      count.start();
    });

    watchRun(
      () => props.value,
      value => count?.update(value),
    );

    return () => <span id={id}>{props.value}</span>;
  },
});
