import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { DataTable } from '@/components/datatable/DataTable';
import { PacienteFones } from '@/components/paciente/PacienteComponents';
import { Avatar } from '@/components/utils/Avatar';
import { useState } from '@/lib/composables';
import { useRouter } from '@/lib/composables/utils/useRouter';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { DashboardService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IDashboardAniversariantesData, IDataTableHeader } from '@/typings';
import { PacientesAniversariantesHeader } from './pacientesAniversariantesCard/PacientesAniversariantesHeader';

export const PacientesAniversariantesCard = createComponent({
  name: 'PacientesAniversariantesCard',
  setup(props, ctx) {
    const $state = useState(s => s.dashboard.aniversariantes);

    const headers = useHeaders();

    const { handleRowClick } = useEvents();

    return () => {
      const { data, loading } = $state.value;

      return (
        <DashboardCard
          title="Pacientes Aniversariantes"
          updateAction={DashboardService.pacientesAniversariantes}
        >
          <PacientesAniversariantesHeader />

          <DataTable
            headers={headers}
            items={data}
            loading={loading}
            noDataText="Nenhum aniversariante nesta data"
            clickableRow
            class="w-full"
            onRowClick={handleRowClick}
          />
        </DashboardCard>
      );
    };
  },
});

function useHeaders(): IDataTableHeader<IDashboardAniversariantesData>[] {
  return [
    {
      text: 'Nome',
      value: 'nome',
      slot: ({ item }) => (
        <div class="flex items-center">
          <Avatar imgSrc={item.imagemUrl} nome={item.nome} small />

          <div class="ml-2 text-subtitle">{item.nome}</div>
        </div>
      ),
    },
    {
      text: 'Idade',
      value: 'idade',
      slot: ({ item }) => (
        <div class="flex items-center">
          <v-icon size="18" class="mr-1">
            {MyIcons.aniversariantes}
          </v-icon>

          <div class="mr-2 text-subtitle">
            {DateHelpers.idade(item.dataNascimento, true)}
          </div>

          <div class="text-gray-600 text-small">
            ({DateHelpers.formatDate(item.dataNascimento)})
          </div>
        </div>
      ),
    },
    {
      text: 'Telefones',
      value: 'telefones',
      slot: ({ item }) => (
        <PacienteFones
          celular={item.celular}
          telefoneCasa={item.telefoneCasa}
          small
          classes="text-gray-600 text-small"
        />
      ),
    },
  ];
}

function useEvents() {
  function handleRowClick(v: IDashboardAniversariantesData) {
    useRouter().push(Routes.app.pacientes.edit.index(v.id));
  }

  return { handleRowClick };
}
