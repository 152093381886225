import { PieChart } from '@/components/charts/PieChart';
import { DashboardSubtitle } from '@/components/dashboard/DashboardSubtitle';
import { useState } from '@/lib/composables';
import { ChartHelpers } from '@/lib/helpers/chart.helpers';
import { createComponent } from '@/lib/vue';

export const ProcedimentosPorConvenioChart = createComponent({
  name: 'ProcedimentosPorConvenioChart',
  setup(props, ctx) {
    const $data = useState(s => s.dashboard.atendimentos.porConvenios);

    return () => (
      <div class="flex flex-col w-full sm:1/2">
        <DashboardSubtitle text="Procedimentos x Convênio" />

        <PieChart
          type="pie"
          value={$data.value}
          colors={ChartHelpers.pie.colors()}
          tooltipFormat={{ money: false, integer: true }}
        />
      </div>
    );
  },
});
