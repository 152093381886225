import { FormFields } from '@/components/form/fields/FormFields';
import { MyPage } from '@/components/page/MyPage';
import { useState } from '@/lib/composables';
import { dateRangePresetsDashboard } from '@/lib/constants/dateRangePresets';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { DashboardService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { DashboardState } from '@/store/modules/dashboard.store';
import { userIsAdminAccount, userIsAdminClinica } from '@/store/utils/auth';
import { IDashboardHeader, IFormSchema } from '@/typings';
import { computed } from '@vue/composition-api';

export const DashboardHeader = createComponent({
  name: 'DashboardHeader',
  setup(props, ctx) {
    const $schema = useSchema();

    const { $model, handleModelChange } = useModel();

    const { handleRefresh } = useActions();

    return () => (
      <MyPage id="DashboardHeader" title="Visão geral" class="w-full">
        <div class="flex items-center py-6 pl-2 pr-8">
          <FormFields
            schema={$schema.value}
            value={$model.value}
            onInput={handleModelChange}
          />

          <v-btn text color="accent" onClick={handleRefresh} class="mb-2">
            <v-icon>{MyIcons.refresh}</v-icon>
            Atualizar
          </v-btn>
        </div>
      </MyPage>
    );
  },
});

function useSchema() {
  const $profissionais = useState(
    s =>
      s.auth.clinica?.profissionais.map(v => ({
        label: v.nome,
        value: v.id,
      })) || [],
  );

  const $periodo = useState(s => s.dashboard.header.periodo);

  return computed<IFormSchema<IDashboardHeader>>(() => ({
    periodo: {
      label: 'Período',
      type: 'date-range',
      hideDetails: true,
      presets: dateRangePresetsDashboard,
      layout: { width: 300, marginBottom: 4 },
      max: DateHelpers.parse($periodo.value?.startDate)?.plus({ months: 12 }),
      min: DateHelpers.parse($periodo.value?.endDate)?.minus({ months: 12 }),
    },
    profissionalId: {
      label: 'Profissional',
      type: 'select',
      items: $profissionais.value,
      hideDetails: true,
      hidden:
        (!userIsAdminAccount() && !userIsAdminClinica()) ||
        $profissionais.value.length <= 1,
      layout: { sm: 6, marginBottom: 4 },
    },
  }));
}

function useModel() {
  const $model = useState(s => s.dashboard.header);

  function handleModelChange({ profissionalId, periodo }: IDashboardHeader) {
    DashboardService.changeProfissional(profissionalId);

    DashboardState.setPeriodo(periodo);

    DashboardService.loadPage();
  }

  return {
    $model,
    handleModelChange,
  };
}

function useActions() {
  DashboardService.init();

  return {
    handleRefresh() {
      DashboardService.loadPage(true);
    },
  };
}
