import { LineChart } from '@/components/charts/LineChart';
import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { useState } from '@/lib/composables';
import { ChartHelpers } from '@/lib/helpers/chart.helpers';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { DashboardService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IChartYScale, IDashboardState, ILineChartData } from '@/typings';
import { computed, ComputedRef } from '@vue/composition-api';

export const AtendimentosPeriodoCard = createComponent({
  name: 'AtendimentosPeriodoCard',
  setup(props, ctx) {
    const $state = useState(s => s.dashboard);

    const $chartData = useChartData($state);

    const $yScale = useYScale($chartData);

    return () => {
      const { data, loading } = $state.value.atendimentosPeriodo;

      return (
        <DashboardCard
          title="Atendimentos no período"
          updateAction={DashboardService.atendimentosPeriodo}
          noData={data.length === 0}
          loading={loading}
        >
          <LineChart
            value={$chartData.value}
            yScale={$yScale.value}
            xScale={{ title: 'Período' }}
          />
        </DashboardCard>
      );
    };
  },
});

function useChartData($state: ComputedRef<IDashboardState>) {
  return computed<ILineChartData[]>(() => {
    const { periodo } = $state.value.header;

    return [
      {
        label: 'Atendimentos',
        data: ChartHelpers.line.mapDates({
          startDate: periodo?.startDate,
          endDate: periodo?.endDate,
          data: $state.value.atendimentosPeriodo.data,
        }),
        color: MyTheme.green500,
      },
    ];
  });
}

function useYScale($chartData: ReturnType<typeof useChartData>) {
  return computed<IChartYScale>(() => {
    const { data } = $chartData.value[0];

    return {
      title: 'Atendimentos',
      max: data.length ? Math.max(...data.map(v => v.value)) + 1 : undefined,
      integer: true,
      beginAtZero: true,
    };
  });
}
