import { FormFields } from '@/components/form/fields/FormFields';
import { useState, useValue } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { DashboardService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { DashboardState } from '@/store/modules/dashboard.store';
import { IFormSchema, IInputItems, IModel } from '@/typings';
import { computed, Ref } from '@vue/composition-api';

export const PacientesAniversariantesHeader = createComponent({
  name: 'PacientesAniversariantesHeader',
  setup(props, ctx) {
    const { $model, handleModelChange } = useModel();

    const $schema = useSchema($model);

    return () => (
      <FormFields
        schema={$schema.value}
        v-model={$model.value}
        onInput={handleModelChange}
        class="mr-auto dense-input px-2"
      />
    );
  },
});

function useSchema($model: Ref<IPacientesAniversariantesParamsModel>) {
  const $diasItems = computed<IInputItems[]>(() => {
    const length = DateHelpers.daysInMonth($model.value.mes) || 31;

    return [
      { label: 'Todos', value: '0' },
      ...Array.from({ length }, (_, i) => {
        const num = (i + 1).toString();

        return { label: num, value: num };
      }),
    ];
  });

  return computed<IFormSchema<IPacientesAniversariantesParamsModel>>(() => ({
    dia: {
      label: 'Dia',
      type: 'select',
      items: $diasItems.value,
      hideDetails: true,
      layout: { width: 140 },
    },
    mes: {
      label: 'Mês',
      type: 'select',
      items: ConstantsHelper.meses,
      hideDetails: true,
      layout: { width: 160 },
    },
  }));
}

interface IPacientesAniversariantesParamsModel extends IModel {
  dia: string;
  mes: string;
}

function useModel() {
  const $state = useState(s => s.dashboard.aniversariantes.params);

  const [$model] = useValue<IPacientesAniversariantesParamsModel>({
    dia: $state.value.mesTodo ? '0' : $state.value.dia?.toString() || '0',
    mes: $state.value.mes?.toString() || '1',
  });

  function handleModelChange({
    dia,
    mes,
  }: IPacientesAniversariantesParamsModel) {
    DashboardState.setAniversariantesParams({
      dia: parseInt(dia, 10) || 0,
      mes: parseInt(mes, 10),
      mesTodo: dia === 'Todos',
    });

    DashboardService.pacientesAniversariantes();
  }

  return { $model, handleModelChange };
}
