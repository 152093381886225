import { QuickMenu } from '@/components/menus/QuickMenu';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { IMenu } from '@/typings';
import { PropType } from 'vue';

interface IProps {
  title?: string;
  menus?: IMenu[];
  updateAction?: () => void;
  loading?: boolean;
  noData?: boolean;
  flat?: boolean;
  maxWidth?: number;
  noPadding?: boolean;
}

interface IEvents {}

export const DashboardCard = createComponent<IProps, IEvents>({
  name: 'DashboardCard',
  props: {
    title: String,
    loading: { type: Boolean, default: false },
    menus: { type: Array as PropType<IMenu[]> },
    updateAction: { type: Function },
    noData: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    maxWidth: Number,
    noPadding: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <v-card
          loading={props.loading}
          disabled={props.loading}
          flat={props.flat}
          max-width={props.maxWidth}
        >
          {header(props)}

          <div
            class={[
              'relative flex flex-col items-center justify-center min-h-20',
              { 'px-4 pb-4': !props.noPadding },
            ]}
          >
            {defaultSlot}

            {overlay(props)}
          </div>
        </v-card>
      );
    };
  },
});

function header(props: IProps) {
  if (!props.title && !props.menus) return null;

  return (
    <div class="flex items-center justify-between p-3 text-cyan-800">
      {props.title && <div class="truncate text-headline">{props.title}</div>}

      {cardMenus(props)}
    </div>
  );
}

function overlay(props: IProps) {
  if (!props.noData) return null;

  return (
    <div
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)' }}
      class="absolute inset-0 flex items-center justify-center p-4 text-center z-5"
    >
      <div class="text-headline text-coolGray-700">
        Dados insuficientes para o período selecionado
      </div>
    </div>
  );
}

function cardMenus(props: IProps) {
  let menus: IMenu[] = [];

  if (props.updateAction) {
    menus.push({
      text: 'Atualizar',
      action: props.updateAction,
      icon: MyIcons.refresh,
    });
  }

  if (props.menus) {
    menus = [...menus, ...props.menus];
  }

  return (
    menus.length && (
      <QuickMenu
        menus={menus}
        icon={MyIcons.settings}
        iconColor={MyTheme.cyan700}
      />
    )
  );
}
