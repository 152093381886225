import { createComponent } from '@/lib/vue';

export const DashboardSubtitle = createComponent({
  name: 'DashboardSubtitle',
  props: {
    text: { type: String, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex py-2 text-title text-coolGray-600">{props.text}</div>
    );
  },
});
