import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { NumberCountUp } from '@/components/utils/NumberCountUp';
import { useState } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { DashboardService } from '@/lib/services';
import { createComponent } from '@/lib/vue';

export const AgendamentoStatusCard = createComponent({
  name: 'AgendamentoStatusCard',
  setup(props, ctx) {
    const $status = useState(s => s.dashboard.agendamentoStatus);

    return () => {
      const { agendado, confirmado, atendido, cancelado, faltou, loading } =
        $status.value;

      return (
        <DashboardCard
          title="Situação do período"
          updateAction={DashboardService.agendamentosStatus}
          loading={loading}
        >
          <div class="flex flex-wrap w-full">
            {statusDiv({
              icon: MyIcons.agendamentoStatus.agendado,
              value: agendado,
              label: 'Pacientes agendados',
              color: MyTheme.coolGray600,
            })}

            {statusDiv({
              icon: MyIcons.agendamentoStatus.confirmado,
              value: confirmado,
              label: 'Pacientes confirmados',
              color: MyTheme.blue600,
            })}

            {statusDiv({
              icon: MyIcons.agendamentoStatus.pacienteAtendido,
              value: atendido,
              label: 'Pacientes atendidos',
              color: MyTheme.green600,
            })}

            {statusDiv({
              icon: MyIcons.agendamentoStatus.cancelado,
              value: cancelado,
              label: 'Pacientes que cancelaram',
              color: MyTheme.yellow700,
            })}

            {statusDiv({
              icon: MyIcons.agendamentoStatus.pacienteFaltou,
              value: faltou,
              label: 'Pacientes que faltaram',
              color: MyTheme.red600,
            })}
          </div>
        </DashboardCard>
      );
    };
  },
});

const statusDiv = ({
  icon,
  value,
  label,
  color,
}: {
  icon: string;
  value: number;
  label: string;
  color: string;
}) => (
  <div class="flex flex-col px-4 text-center">
    <div>
      <v-icon size={40} color={color}>
        {icon}
      </v-icon>
    </div>

    <NumberCountUp
      class="block my-2 text-display"
      style={{ color }}
      value={value}
    />

    <div class="text-subtitle-sm" style={{ color }}>
      {label}
    </div>
  </div>
);
