import { createComponent } from '@/lib/vue';
import { AgendamentoStatusCard } from './components/cards/AgendamentoStatusCard';
import { AtendimentosCard } from './components/cards/AtendimentosCard';
import { AtendimentosPeriodoCard } from './components/cards/AtendimentosPeriodoCard';
import { DistribuicaoEtariaCard } from './components/cards/DistribuicaoEtariaCard';
import { PacientesAniversariantesCard } from './components/cards/PacientesAniversariantesCard';
import { PacientesCard } from './components/cards/PacientesCard';
import { DashboardHeader } from './components/DashboardHeader';

export default createComponent({
  name: 'DashboardPage',
  setup(props, ctx) {
    return () => (
      <div id="DashboardPage" class="flex flex-col space-y-6">
        <DashboardHeader />

        <AgendamentoStatusCard />

        <AtendimentosPeriodoCard />

        <AtendimentosCard />

        <DistribuicaoEtariaCard />

        <PacientesCard />

        <PacientesAniversariantesCard />
      </div>
    );
  },
});
