import { createComponent } from '@/lib/vue';
import { IMenu } from '@/typings';
import { PropType } from 'vue';
import { mapMenuTile } from './mapMenuTile';

export interface IProps {
  icon: string;
  iconColor?: string;
  btnClasses?: string;
  menus: IMenu[];
  maxWidth?: number;
}

export const QuickMenu = createComponent<IProps>({
  name: 'QuickMenu',
  props: {
    icon: { type: String, required: true },
    iconColor: String,
    btnClasses: { type: String, default: '' },
    menus: { type: Array as PropType<IMenu[]>, required: true },
    maxWidth: { type: Number, default: 320 },
  },
  setup(props, ctx) {
    return () => (
      <v-menu
        bottom
        left
        light
        offset-y
        nudge-left={10}
        max-width={props.maxWidth}
        scopedSlots={{ activator: menuActivator(props) }}
      >
        <v-list>{props.menus.map(mapMenuTile)}</v-list>
      </v-menu>
    );
  },
});

const menuActivator =
  (props: IProps) =>
  ({ on }) =>
    (
      <v-btn
        icon
        dark
        class={['px-1 no-focus', props.btnClasses]}
        ripple={false}
        {...{ on }}
      >
        <v-icon color={props.iconColor}>{props.icon}</v-icon>
      </v-btn>
    );
