import { PieChart } from '@/components/charts/PieChart';
import { DashboardSubtitle } from '@/components/dashboard/DashboardSubtitle';
import { useState } from '@/lib/composables';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { IDashboardResultData, IPieChartData } from '@/typings';

export const PacientesPorTipoChart = createComponent({
  name: 'PacientesPorTipoChart',
  setup(props, ctx) {
    const $data = useState(s => s.dashboard.pacientes.porTipo);

    return () => {
      return (
        <div class="flex flex-col w-full sm:1/2">
          <DashboardSubtitle text="Pacientes por tipo" />

          <PieChart
            semi
            value={mapChartData($data.value)}
            tooltipFormat={{ money: false, integer: true }}
          />
        </div>
      );
    };
  },
});

function mapChartData(data: IDashboardResultData[]): IPieChartData[] {
  return data.map(v => {
    const colors = {
      NOVO: MyTheme.green600,
      RECORRENTE: MyTheme.green400,
    };

    return {
      ...v,
      color: colors[v.label],
    };
  });
}
