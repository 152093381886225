import { IMenu } from '@/typings';

export function mapMenuTile(menu: IMenu, index: number) {
  if (menu.hidden) {
    return null;
  } else if (menu.to) {
    return (
      <v-list-item key={index} exact to={menu.to}>
        {menuListItem(menu)}
      </v-list-item>
    );
  } else if (menu.action) {
    return (
      <v-list-item key={index} onClick={menu.action}>
        {menuListItem(menu)}
      </v-list-item>
    );
  } else if (menu.divider) {
    return <v-divider key={index} />;
  }
}

function menuListItem(menu: IMenu) {
  return [
    <v-list-item-action>
      <v-icon>{menu.icon}</v-icon>
    </v-list-item-action>,

    <v-list-item-content>
      <v-list-item-title>{menu.text}</v-list-item-title>

      {menu.description && (
        <v-list-item-subtitle>{menu.description}</v-list-item-subtitle>
      )}
    </v-list-item-content>,
  ];
}
