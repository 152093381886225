import { useElementRef } from '@/lib/composables/utils/useElementRef';
import { IFormatNumberOptions } from '@/lib/form';
import { ChartHelpers } from '@/lib/helpers/chart.helpers';
import { createComponent, watchRun } from '@/lib/vue';
import { IChartXScale, IChartYScale, ILineChartData } from '@/typings';
import { onMounted } from '@vue/composition-api';
import {
  CategoryScale,
  Chart,
  ChartData,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { PropType } from 'vue';

Chart.register(
  CategoryScale,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
);

type ILineChart = Chart<'line', number[], string>;

interface IProps {
  value: readonly ILineChartData[];
  yScale?: IChartYScale;
  xScale?: IChartXScale;
  height?: number;
  tooltipFormat?: IFormatNumberOptions;
}

export const LineChart = createComponent<IProps>({
  name: 'LineChart',
  props: {
    value: { type: Array as PropType<ILineChartData[]>, required: true },
    yScale: Object as PropType<IChartYScale>,
    xScale: Object as PropType<IChartXScale>,
    height: { type: Number, default: 300 },
    tooltipFormat: Object as PropType<IFormatNumberOptions>,
  },
  setup(props, ctx) {
    let chart: ILineChart | null = null;

    const { elementId: canvasId, getElement: getCanvas } =
      useElementRef<HTMLCanvasElement>();

    onMounted(() => {
      const canvasEl = getCanvas();
      if (!canvasEl) {
        throw Error('canvas element not found');
      }

      chart = createChart({ props, canvasEl });
    });

    watchRun(props as any, () => updateChart({ chart, props }));

    return () => (
      <div id="LineChart" style={`height: ${props.height}px`} class="w-full">
        <canvas id={canvasId} />
      </div>
    );
  },
});

function createChart({
  props,
  canvasEl,
}: {
  props: IProps;
  canvasEl: HTMLCanvasElement;
}) {
  return new Chart(canvasEl.getContext('2d')!, {
    type: 'line',
    data: mapData(props),
    options: mapOptions(props),
  });
}

function updateChart({
  chart,
  props,
}: {
  chart: ILineChart | null;
  props: IProps;
}) {
  if (!chart) return;

  chart.data = mapData(props);

  chart.options = mapOptions(props);

  chart.update();
}

function mapOptions(props: IProps): ILineChart['options'] {
  const labels = mapLabels(props);

  const { xScale = {}, yScale = {} } = props;

  return {
    locale: 'pt-BR',
    spanGaps: true,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: ChartHelpers.tooltipLabelCb({
            format: props.tooltipFormat,
            type: 'line',
          }),
        },
      },
    },
    scales: {
      y: {
        beginAtZero: yScale.beginAtZero,
        min: yScale.min,
        max: yScale.max,
        ticks: {
          precision: yScale.integer ? 0 : yScale.precision,
          format: yScale.format,
        },
        title: {
          display: !!yScale.title,
          text: yScale.title,
          font: { size: 12, weight: '500' },
        },
      },
      x: {
        ticks: {
          callback: (_, idx: number) => {
            const stepSize = Math.round(labels.length / 10) || 1;

            return idx % (xScale.stepSize || stepSize) === 0 ? labels[idx] : '';
          },
        },
        title: {
          display: !!xScale.title,
          text: xScale.title,
          font: { size: 12, weight: '500' },
        },
      },
    },
    font: { family: '"Roboto", sans-serif' },
  };
}

function mapData(props: IProps): ChartData<'line', number[], string> {
  return {
    labels: mapLabels(props),
    datasets: props.value.map(v => ({
      label: v.label,
      data: v.data.map(d => d.value),
      // styling
      borderColor: v.color,
      backgroundColor: v.color,
      fill: false,
      pointRadius: 5,
      pointHitRadius: 15,
      pointBorderWidth: 2,
      pointBackgroundColor: '#ffffff',
      pointHoverRadius: 10,
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderWidth: 3,
      tension: 0.2,
    })),
  };
}

function mapLabels(props: IProps) {
  return props.value[0].data.map(v => v.label);
}
