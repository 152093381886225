import { PieChart } from '@/components/charts/PieChart';
import { DashboardSubtitle } from '@/components/dashboard/DashboardSubtitle';
import { useState } from '@/lib/composables';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { IDashboardResultData, IPieChartData, Sexo } from '@/typings';

export const PacientesPorSexoChart = createComponent({
  name: 'PacientesPorSexoChart',
  setup(props, ctx) {
    const $data = useState(s => s.dashboard.pacientes.porSexo);

    return () => (
      <div class="flex flex-col w-full sm:1/2">
        <DashboardSubtitle text="Pacientes x Sexo" />

        <PieChart
          value={mapChartData($data.value)}
          tooltipFormat={{ money: false, integer: true }}
        />
      </div>
    );
  },
});

function mapChartData(data: IDashboardResultData[]): IPieChartData[] {
  return data.map(v => {
    const colors = {
      [Sexo.MASCULINO]: MyTheme.blue400,
      [Sexo.FEMININO]: MyTheme.rose400,
    };

    return {
      ...v,
      color: colors[v.label],
    };
  });
}
